import React, { useState, useRef, useEffect } from "react";
import "./clientNotesDisplay.css";
import PlusMinusCollapse from "../../../../components/PlusMinusCollapse/PlusMinusCollapse";
import { Popper, useMediaQuery } from "@mui/material";
import BAGroupTimeFormat from "../../../../utils/formatters/BAGroupTimeFormat";
import {
  ADD_CLIENT_NOTE,
  DELETE_CLIENT_NOTE,
  ASTERISK_CLEANUP,
} from "../../../../utils/GraphQL/mutations";
import { useMutation } from "@apollo/client";
import Auth from "../../../../utils/auth";
import {
  CssTextFieldStandard,
  MyFormHelperText,
} from "../../../../components/CssTextFields/CssTextFieldStandard";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import UpdateNoteModal from "../UpdateNoteModal/UpdateNoteModal";
import copyStringToClipboard from "../../../../utils/formatters/copyStringToClipboard";
// import pasteKeapNote from "../../../../utils/formatters/pasteKeapNote";
import OfficeShortcuts from "../OfficeShortcuts/OfficeShortcuts";

const clearedNote = {
  note: "",
  dateCreated: "",
  dateUpdated: "",
  updatedBy: "",
  createdBy: "",
};

const clearedInfoObj = {
  noteId: "",
  updatedDate: "",
  createdDate: "",
};

const ClientNotesDisplay = ({
  heightTrigger,
  notesArr,
  clientId,
  queryClient,
  handleSnack,
  gateCode,
  invoicesArr,
  phnNumArr,
}) => {
  const [barCollapsed, setBarCollapsed] = useState(true);
  const [inputError, setInputError] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [addNoteShow, setAddNoteShow] = useState(false);
  const [newNote, setNewNote] = useState({ ...clearedNote });
  const [updateNoteObj, setUpdateNoteObj] = useState({ ...clearedNote });
  const [createNewNote] = useMutation(ADD_CLIENT_NOTE);
  const [deleteNote] = useMutation(DELETE_CLIENT_NOTE);
  const [asteriskCall] = useMutation(ASTERISK_CLEANUP);
  const [notesArrState, setNotesArrState] = useState([]);
  const smaller900 = useMediaQuery("(max-width:900px)");
  const [anchorEl, setAnchorEl] = useState(null);
  const clientNotesDiv = useRef();
  const clientNoteInfoRef = useRef([]);
  const inputRef = useRef();
  const [selectionRange, setSelectionRange] = useState(null);
  const [noteInfoObj, setNoteInfoObj] = useState({ ...clearedInfoObj });
  const [infoObjClicked, setInfoObjClicked] = useState(false);

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (notesArr?.length > 0) {
      const reverseOrder = notesArr?.slice()?.sort((a, b) => {
        const curNum = a?.dateUpdated || a?.dateCreated;
        const nextNum = b?.dateUpdated || b?.dateCreated;
        const cur = parseInt(curNum);
        const next = parseInt(nextNum);

        if (cur > next) {
          return -1;
        }
        if (cur < next) {
          return 1;
        }
        return 0;
      });

      asteriskCleanup(reverseOrder);

      setNotesArrState(reverseOrder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notesArr]);

  useEffect(() => {
    if (selectionRange) {
      inputRef?.current?.setSelectionRange(selectionRange, selectionRange);
    }
  }, [selectionRange]);

  const getUser = async () => {
    const user = await Auth.getProfile();

    if (user?.data.firstName) {
      setUserName(user?.data.firstName);
    }
    return;
  };

  const handleChange = (event) => {
    let target = event.target;
    let value = target.value;
    let selectionRangeVal = null;

    //regex to look behind and only find double asterisk that
    //isn't preceded by another asterisk. only finding the closing asterisks.
    const breakOutOfAsterisk = (prevNote, forwardBool) => {
      const regex = forwardBool
        ? /(?<=\*\*)([ \t]?\w{1}[\w\W\s]*?)(?=\*{2})/gm
        : /\*{4}/gim;
      const cursorStart = forwardBool
        ? target.selectionStart - 1
        : target.selectionStart;
      const matched = prevNote?.match(regex);
      const indexOfObjArr = matched?.map((str) => {
        const start = prevNote?.indexOf(str);
        const end = start + str?.length;
        return { start, end };
      });
      // console.log("matched", matched);
      // console.log(indexOfObjArr);
      // console.log(cursorStart);

      let returnedItem = { cursorPos: null, prevNote: null };

      indexOfObjArr?.forEach((obj) => {
        let splicedNote = "";

        if (forwardBool) {
          if (obj?.start <= cursorStart && obj?.end >= cursorStart) {
            const afterAsterisk = obj?.end + 2;
            splicedNote =
              prevNote?.slice(0, afterAsterisk) +
              "\n" +
              prevNote?.slice(afterAsterisk);
            returnedItem = {
              cursorPos: afterAsterisk + 1,
              prevNote: splicedNote,
            };
          }
        }

        if (!forwardBool) {
          if (obj?.start <= cursorStart && obj?.end > cursorStart) {
            splicedNote =
              prevNote?.slice(0, obj?.start) + prevNote?.slice(obj?.end);
            returnedItem = {
              cursorPos: obj?.start,
              prevNote: splicedNote,
            };
          }
        }
      });

      return returnedItem;
    };

    // Regex test for triple asterisk shortcut to
    // set double asterisk start and end and cursor middle
    const shortcutRegex = smaller900
      ? /(?<![c])[c]{3}(?![c]+)/gi
      : /(?<!\*)\*{3}(?!\*+)/gi;

    if (shortcutRegex.test(value)) {
      const replacement = value?.length === 3 ? "****" : "\n****";
      selectionRangeVal =
        value?.length === 3 ? target.selectionStart - 1 : target.selectionStart;
      value = target.value?.replace(shortcutRegex, replacement);
    }

    setNewNote((prev) => {
      const date = Date.now().toString();
      const type = event?.nativeEvent?.inputType;
      if (type === "insertLineBreak" || type === "deleteContentBackward") {
        const forwardBool = type === "insertLineBreak" ? true : false;
        const { cursorPos, prevNote } = breakOutOfAsterisk(
          prev?.note,
          forwardBool
        );
        selectionRangeVal = cursorPos;

        return {
          note: prevNote || value,
          dateCreated: date,
          dateUpdated: "",
          updatedBy: "",
          createdBy: userName,
        };
      }

      return {
        note: value,
        dateCreated: date,
        dateUpdated: "",
        updatedBy: "",
        createdBy: userName,
      };
    });

    setSelectionRange(selectionRangeVal);

    return;
  };

  const asteriskCleanup = async (noteArr) => {
    if (noteArr?.length <= 0) {
      return;
    }

    // Cleanup too many asterisks check
    const regex = /\*{4,}/gim;
    const updateArr = noteArr?.map((item) => {
      // console.log(regex.test(item?.note));
      if (regex.test(item?.note)) {
        const updatedNote = item?.note?.replaceAll(regex, "**");
        // console.log(updatedNote)

        return { id: item?._id, note: updatedNote };
      }

      return null;
    });

    const noNullArr = updateArr?.filter((item) => item !== null);
    if (noNullArr?.length > 0) {
      const { data } = await asteriskCall({
        variables: {
          noteArr: [...noNullArr],
        },
      });

      if (data?.asteriskCleanup) {
        if (handleSnack) {
          handleSnack("Notes Asterisk Cleaned Up");
        }
        queryClient();
      }
    }
  };

  const getCalendarNotesAndCopyToClipboard = () => {
    const notes = notesArrState?.map((noteObj, index) => {
      const regex = /(?<=\*\*)([ \t]?\w{1}[\w\W\s]*?)(?=\*{2})/gm;
      const note = noteObj?.note;
      const matched = note?.match(regex);
      if (matched?.length > 0) {
        // const dateStr = noteObj?.dateUpdated
        //   ? BAGroupTimeFormat(noteObj?.dateUpdated, true, true, true)
        //   : BAGroupTimeFormat(noteObj?.dateCreated, true, true, true);
        //   const splitDate = dateStr?.split(" ");
        // ${splitDate[0]} ${splitDate[1]}, ${splitDate[2]}\n

        const joinedNotes = matched?.join("\n- ");

        return `- ${joinedNotes}`;
      }
      return null;
    });

    const activeInvoices = invoicesArr?.filter(
      (invObj) => invObj?.invoicePaid === false
    );
    const host = window.location.host;
    const domain = /localhost/gi.test(host)
      ? host
      : "https://bigginsdoorrefinishing.com";
    const paymentLink =
      activeInvoices?.length === 1 && activeInvoices[0]?.id
        ? `${domain}/login-portal/payment/${activeInvoices[0]?.id}`
        : "";

    if (activeInvoices?.length > 1) {
      if (handleSnack) {
        handleSnack(
          "More then 1 invoice is active. Cannot copy payment link.",
          5000
        );
      }
    }

    const phnNumOnlyStr =
      phnNumArr?.length > 0
        ? phnNumArr
            ?.map((numObj) => {
              return numObj?.phoneNumber;
            })
            ?.join("\n")
        : "";

    const hasGateCode = gateCode ? `Gate Code: ${gateCode}` : "";

    const allNotesStr = `${hasGateCode}\n${notes?.join(
      "\n"
    )?.trim()}\n\n${phnNumOnlyStr}\n\n${paymentLink}`?.trim();

    // const allNotesStr = gateCode
    //   ? paymentLink
    //     ? `Gate Code: ${gateCode}\n${notes?.join("")} \n\n${paymentLink}`
    //     : `Gate Code: ${gateCode}\n${notes?.join("")} `
    //   : paymentLink
    //   ? `${paymentLink}\n\n${notes?.join("")} \n\n${paymentLink}`
    //   : notes?.join("");

    copyStringToClipboard(allNotesStr);
    if (handleSnack) {
      const text = paymentLink
        ? `Cal Notes & Payment Link Copied!`
        : "Calendar Notes Copied!";
      handleSnack(text, 2500);
    }
    return;
  };

  const closeInfoObj = () => {
    if (infoObjClicked) {
      return;
    }
    setAnchorEl(null);
  };

  const handleNewNote = async () => {
    if (addNoteShow === false) {
      setAddNoteShow((prev) => !prev);
      return;
    }

    if (newNote?.note?.length <= 0) {
      setInputError(true);
    }

    const { data } = await createNewNote({
      variables: {
        clientId,
        noteObj: { ...newNote, note: newNote?.note?.trim() || newNote?.note },
      },
    });

    if (data?.addClientNote) {
      setNewNote({ ...clearedNote });
      setAddNoteShow(false);
      handleSnack("Note Saved", 2500);
      queryClient();
    }
  };

  const handleNoteDelete = async (noteId) => {
    const { data } = await deleteNote({
      variables: {
        clientId,
        noteId,
      },
    });

    if (data?.deleteClientNote) {
      handleSnack("Note Deleted", 2500);
      queryClient();
    }
  };

  const closeModal = () => {
    setUpdateNoteObj({ ...clearedNote });
    setSelectionRange(null);
    setEditModalOpen(false);
  };

  const handleUpdate = (good) => {
    if (good) {
      handleSnack("Note Updated", 2500);
      queryClient();
      closeModal();

      return;
    }

    handleSnack("Something went wrong...", 2500);
    closeModal();

    return;
  };

  return (
    <div
      id="client-notes-height-marker"
      className="client-notes-container"
      ref={clientNotesDiv}
    >
      <UpdateNoteModal
        updateCB={handleUpdate}
        userName={userName}
        incNote={updateNoteObj}
        open={editModalOpen}
        close={closeModal}
      />
      <button
        onClick={() => {
          if (heightTrigger) {
            heightTrigger();
          }

          setTimeout(() => {
            if (smaller900 === true && barCollapsed === true) {
              const navHeight = document.querySelector(
                "#admin-nav-height-marker"
              )?.offsetHeight;
              const pagePos = clientNotesDiv.current.offsetTop;

              const totalMobileScroll = pagePos - navHeight - 5;
              window.scrollTo(0, totalMobileScroll);
            }

            if (smaller900 === false && barCollapsed === true) {
              clientNotesDiv.current.scrollIntoView({ block: "start" });
            }
          }, 500);

          setBarCollapsed((prev) => !prev);
        }}
        className="header-buttons-collapse-sticky"
      >
        <span>Client Notes</span>
        <PlusMinusCollapse plus={barCollapsed} />
      </button>
      <div className={barCollapsed ? "content-hide" : "content-show"}>
        <div className="client-note-btn-box">
          {addNoteShow && (
            <button
              className="single-client-btn"
              onClick={() => {
                setNewNote({ ...clearedNote });
                setSelectionRange(null);
                setAddNoteShow((prev) => !prev);
                setInputError(false);
              }}
            >
              Cancel
            </button>
          )}
          <button className="single-client-btn" onClick={handleNewNote}>
            {addNoteShow ? "Save Note" : "Add New Note"}
          </button>
          {navigator.clipboard && (
            <button
              className="single-client-btn"
              onClick={getCalendarNotesAndCopyToClipboard}
              title="Copy Notes For Calendar"
            >
              Cal Notes
            </button>
          )}
        </div>
        <div className="client-note-shortcut-note">
          Triple {smaller900 ? "'c'" : "'asterisk'"} to start calendar note
          shortcut. e.g. **Hello there**
        </div>
        <div
          className={addNoteShow ? "client-note-new show" : "client-note-new"}
        >
          <div className="client-note-new-item">
            {addNoteShow && <OfficeShortcuts handleSnack={handleSnack} />}
            {/* <button
                  className="single-client-btn"
                  onClick={async () => {
                    const date = Date.now().toString();
                    const text = await pasteKeapNote(
                      navigator?.clipboard,
                      handleSnack
                    );

                    setNewNote((prev) => {
                      return {
                        note: text,
                        dateCreated: date,
                        dateUpdated: prev?.dateUpdated,
                        updatedBy: prev?.updatedBy,
                        createdBy: userName,
                      };
                    });
                  }}
                >
                  Paste
                </button> */}
            <MyFormHelperText
              errorText={
                "Note seems to contain a gate code. Please ensure gate code is entered in correct box and removed from note."
              }
              formError={/gate code|gate:/gi?.test(newNote?.note)}
            />
            <MyFormHelperText
              errorText={"Please enter note before saving"}
              formError={inputError}
            />
            <CssTextFieldStandard
              inputRef={inputRef}
              sx={{ width: "100%", my: 0.6 }}
              type="text"
              label="New Note: "
              name="note"
              onChange={handleChange}
              onFocus={() => {
                setInputError(false);
              }}
              value={newNote?.note}
              multiline
              minRows={5}
            />
          </div>
        </div>
        {notesArrState?.length > 0 ? (
          <>
            {notesArrState?.map((noteObj, index) => {
              const date = BAGroupTimeFormat(
                noteObj?.dateCreated,
                true,
                true,
                true
              );
              const updatedDate = BAGroupTimeFormat(
                noteObj?.dateUpdated,
                true,
                true,
                true
              );

              const splitDate = updatedDate
                ? updatedDate?.split(" ")
                : date?.split(" ");

              return (
                <div
                  key={noteObj?._id + index}
                  onClick={(event) => {
                    // event.stopPropagation();
                    event.preventDefault();
                    setUpdateNoteObj({ ...noteObj });
                    setEditModalOpen(true);
                  }}
                  className="client-note-item"
                >
                  <div className="client-note-hdr-box">
                    <span className="client-note-title">{`${splitDate[0]} ${splitDate[1]}, ${splitDate[2]}`}</span>
                    <button
                      className="client-note-img-btn"
                      onMouseOver={() => {
                        setNoteInfoObj({
                          noteId: noteObj?._id,
                          updatedDate: updatedDate
                            ? `Updated: ${updatedDate} by ${noteObj?.updatedBy}`
                            : null,
                          createdDate: `Created: ${date} by ${noteObj?.createdBy}`,
                          noteObj,
                        });
                        setAnchorEl(clientNoteInfoRef?.current[index]);
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();

                        if (infoObjClicked) {
                          setInfoObjClicked(false);
                          return;
                        }

                        setNoteInfoObj({
                          noteId: noteObj?._id,
                          updatedDate: updatedDate
                            ? `Updated: ${updatedDate} by ${noteObj?.updatedBy}`
                            : null,
                          createdDate: `Created: ${date} by ${noteObj?.createdBy}`,
                          noteObj,
                        });
                        setAnchorEl(clientNoteInfoRef?.current[index]);
                        setInfoObjClicked(true);
                      }}
                      onBlur={closeInfoObj}
                      onMouseLeave={closeInfoObj}
                    >
                      <InfoOutlinedIcon
                        fontSize="14px"
                        ref={(el) => (clientNoteInfoRef.current[index] = el)}
                      />
                    </button>
                  </div>
                  <p className="client-note-par">{` - ${noteObj?.note}`}</p>
                </div>
              );
            })}
          </>
        ) : (
          <div>
            <h3>No Notes To Display</h3>
          </div>
        )}

        {/* Note information popover */}
        <Popper
          placement="left-start"
          onMouseLeave={closeInfoObj}
          disablePortal={true}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          sx={{ zIndex: 100 }}
          modifiers={[
            {
              name: "flip",
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: "viewport",
                padding: 8,
              },
            },
            {
              name: "preventOverflow",
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary: "viewport",
                padding: 8,
              },
            },
            {
              name: "arrow",
              enabled: true,
              options: {
                element: anchorEl,
              },
            },
          ]}
        >
          <div className="client-note-info-box">
            <ul>
              <li>{noteInfoObj?.createdDate}</li>
              {noteInfoObj?.updatedDate && <li>{noteInfoObj?.updatedDate}</li>}
            </ul>
            <div className="client-note-info-btn-box">
              <div>
                <button
                  className="client-note-btn"
                  onClick={() => {
                    setUpdateNoteObj({ ...noteInfoObj?.noteObj });
                    setEditModalOpen(true);
                  }}
                >
                  Edit
                </button>
                <button
                  className="client-note-func-btn"
                  onClick={() => {
                    handleSnack(
                      <div className="client-note-delete-confirm">
                        <span className="client-note-delete-confirm-text">
                          Are you sure you want to delete note?
                        </span>
                        <button
                          className="client-note-func-btn confirm"
                          onClick={() => {
                            // setLoading(true);
                            handleNoteDelete(noteInfoObj?.noteId);
                          }}
                        >
                          <DeleteIcon
                            sx={{ fontSize: "20px", color: "#b40000" }}
                          />
                          (Delete)
                        </button>
                      </div>,
                      10000
                    );
                  }}
                >
                  <DeleteIcon sx={{ fontSize: "20px", color: "#b40000" }} />
                </button>
              </div>
              <div>
                {infoObjClicked ? (
                  <button
                    className="client-note-btn"
                    onClick={() => {
                      setInfoObjClicked(false);
                      setAnchorEl(null);
                    }}
                  >
                    Close
                  </button>
                ) : (
                  "Click info to hold open"
                )}
              </div>
            </div>
          </div>

          {/* {updatedDate && (
                      <>
                        <br />
                        <br />
                        <span className="client-note-par-small">
                          - Created: {date} by {noteObj?.createdBy}
                        </span>
                      </>
                    )} */}
        </Popper>
      </div>
    </div>
  );
};

export default ClientNotesDisplay;
