import React, { useEffect, useState, useRef } from "react";
import "./clientSearchInput.css";
import { CssTextFieldStandard, CssTextFieldStandardBlackWithBorder } from "../../../../components/CssTextFields/CssTextFieldStandard";
import { CLIENT_SEARCH } from "../../../../utils/GraphQL/queries";
import { useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router";
import {
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
  Popper,
} from "@mui/material";
import capitalizeFirstLetters from "../../../../utils/formatters/capitalizeFirstLetters";

const ClientSearchInput = ({ returnResults, setWidth, menuReturn, handleSnack }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [clientData, setClientData] = useState([]);
  const [callCompleted, setCallCompleted] = useState(true);
  const [clientSearchCall] = useLazyQuery(CLIENT_SEARCH, {
    fetchPolicy: "network-only",
  });
  const elRef = useRef();

  useEffect(() => {
    if (anchorEl) {
      const onScroll = () => {
        blurResults();
      };
      // clean up code
      window.removeEventListener("scroll", onScroll);
      window.addEventListener("scroll", onScroll, { passive: true });
      return () => window.removeEventListener("scroll", onScroll);
    }
    // eslint-disable-next-line
  }, [anchorEl]);
  
  useEffect(() => {
    if (menuReturn?.anchor) {
      setSearchParams("");
    }
    // eslint-disable-next-line
  }, [menuReturn?.anchor]);

  useEffect(() => {
    let timeout = null;
    setCallCompleted(false);

    if (searchParams?.length > 0) {
      timeout = setTimeout(() => {
        delayedSearch();
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, [searchParams]);

  const delayedSearch = async () => {
    const { data } = await clientSearchCall({
      variables: { searchParam: searchParams },
    });

    if (data?.searchClients) {
      setClientData([...data?.searchClients]);
    }

    setCallCompleted(true);
  };

  const blurResults = () => {
    if (anchorEl === null) {
      return;
    }

    let timeout = setTimeout(() => {
      setAnchorEl(null);
      document.activeElement.blur();
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  };

  const navUser = (result) => {
    if (returnResults) {
      returnResults(result);
      return;
    }

    navigate(`/bdr-office/clients/view-client/${result?._id}`);
  };

  return menuReturn?.isMenu ? (
    <Menu
      anchorEl={menuReturn?.anchor}
      disableScrollLock={menuReturn?.matchesMQ}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      open={Boolean(menuReturn?.anchor)}
      onClose={() => {
        menuReturn?.menuClose();
      }}
    >
      <MenuItem>
        <CssTextFieldStandardBlackWithBorder
          sx={{ width: "95%", m: 0, color: "000000" }}
          type="text"
          label="Search Clients"
          name="clientSearch"
          autoComplete="off"
          focused={true}
          onKeyDown={(event) => {
            event.stopPropagation()
          }}
          onChange={(event) => {
            setSearchParams(capitalizeFirstLetters(event?.target?.value));
          }}
          value={searchParams}
          InputProps={{
            endAdornment: !callCompleted && searchParams?.length > 0 && (
              <div
                style={{
                  color: "#000000",
                  fontSize: "inherit",
                }}
              >
                <CircularProgress color="inherit" size={20} />
              </div>
            ),
          }}
          variant="standard"
        />
      </MenuItem>
      {/* Loading menu item */}
      {!callCompleted && searchParams?.length > 0 && (
        <MenuItem>
          <div
            style={{
              color: "#000000",
              fontSize: "inherit",
              textAlign: "center",
              width: "100%",
            }}
          >
            <CircularProgress color="inherit" size={20} />
          </div>
        </MenuItem>
      )}

      {/* results menu items */}
      {callCompleted &&
        (clientData?.length > 0 ? (
          clientData?.map((item, index) => {
            return (
              <MenuItem
                key={`client-popover-item-${item?._id}-${index}`}
                className="client-search-result-item"
                onClick={(event) => {
                  event.preventDefault();
                  navUser(item);
                  // console.log(item?._id);
                }}
              >
                <div>{`${item?.firstName} ${item?.lastName} - `}</div>
                <div>{item?.primaryEmail}</div>
              </MenuItem>
            );
          })
        ) : (
          <MenuItem>
            <Typography>No Results</Typography>
          </MenuItem>
        ))}
        <MenuItem
          onClick={menuReturn?.searchClose}
        >
          <Typography>-- Cancel --</Typography>
        </MenuItem>
    </Menu>
  ) : (
    <div
      className="client-search-container"
      style={{ width: setWidth ? setWidth : "100%" }}
    >
      <CssTextFieldStandard
        sx={{ width: "95%", m: 0, color: "#ffffff" }}
        type="text"
        label="Search Clients"
        name="clientSearch"
        autoComplete="off"
        onBlur={blurResults}
        onFocus={(event) => {
          if (event?.target?.value?.length > 0) {
            setAnchorEl(elRef.current);
            return;
          }

          blurResults();
        }}
        onChange={(event) => {
          setSearchParams(capitalizeFirstLetters(event?.target?.value));
          if (event?.target?.value?.length > 0) {
            setAnchorEl(elRef.current);
            return;
          }

          blurResults();
        }}
        value={searchParams}
        InputProps={{
          endAdornment: !callCompleted && searchParams?.length > 0 && (
            <div
              style={{
                color: "#ffffff",
                fontSize: "inherit",
              }}
            >
              <CircularProgress color="inherit" size={20} />
            </div>
          ),
        }}
        variant="standard"
        ref={elRef}
      />

      {/* Results popover listing */}
      <Popper
        placement="bottom-start"
        disablePortal={true}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        sx={{ zIndex: 100 }}
        modifiers={[
          {
            name: "flip",
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: "viewport",
              padding: 8,
            },
          },
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: "viewport",
              padding: 8,
            },
          },
          {
            name: "arrow",
            enabled: true,
            options: {
              element: anchorEl,
            },
          },
        ]}
      >
        {callCompleted &&
          (clientData?.length > 0 ? (
            <div className="client-search-over-div">
              {clientData?.map((item, index) => {
                return (
                  <button
                    key={`client-popover-item-${item?._id}`}
                    className="client-search-result-item"
                    onClick={(event) => {
                      event.preventDefault();
                      navUser(item);
                    }}
                  >
                    <div>{`${item?.firstName} ${item?.lastName} - `}</div>
                    <div>{item?.primaryEmail}</div>
                  </button>
                );
              })}
            </div>
          ) : (
            <div className="client-search-over-div">
              <h5>No Results</h5>
            </div>
          ))}
      </Popper>
    </div>
  );
};

export default ClientSearchInput;
