import React, { useEffect, useState, useRef } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  GET_EMAIL_BY_ID,
  QUERY_CLIENT_PHOTOS,
} from "../../../../utils/GraphQL/queries";
import "./emailModal.css";
import AdminBDRSpinner from "../AdminBDRSpinner/AdminBDRSpinner";
import BAGroupTimeFormat from "../../../../utils/formatters/BAGroupTimeFormat";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Snackbar } from "@mui/material";
import { Buffer } from "buffer";
// import { copyImageToClipboard } from "copy-image-clipboard";
import copyImageToClip from "../../../../utils/copyImageToClip";
import RotateRightIcon from "@mui/icons-material/RotateRight";

const EmailModal = ({ emailId, closeEmail, prepareReply}) => {
  const [dateFormat, setDateFormat] = useState("");
  // const [addedCopyListeners, setAddedCopyListeners] = useState(false);
  const [imgIds, setImgIds] = useState([]);
  // const [btnIdsForCopy, setBtnIdsForCopy] = useState([]);
  // const [imgIdsForCopy, setImgIdsForCopy] = useState([]);
  const htmlRef = useRef();
  const [loadEmail, { data: email, loading: emailLoading }] =
    useLazyQuery(GET_EMAIL_BY_ID);
  const [getIncImgIdData, { loading: imageLoading }] =
    useLazyQuery(QUERY_CLIENT_PHOTOS);
  const [imgData, setImgData] = useState([]);
  const [attachImgs, setAttachImgData] = useState([]);
  const [openSnack, setOpenSnack] = useState({
    visible: false,
    message: "",
    duration: 2000,
  });

  useEffect(() => {
    getImgData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgIds]);

  useEffect(() => {
    loadEmail({
      variables: {
        emailId: emailId,
      },
      fetchPolicy: "network-only"
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailId]);

  useEffect(() => {
    if (email?.getEmailById?.attachImgs?.length > 0) {
      const addedOriginal = [...email?.getEmailById?.attachImgs];
      const updated = addedOriginal.map((arr) => {
        return { ...arr, originalData: arr?.imageData };
      });

      setAttachImgData([...updated]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email?.getEmailById]);

  useEffect(() => {
    setImgIds([]);
    if (email?.getEmailById?.html || email?.getEmailById?.emailText) {
      filterImgIdsInHtml();
    }

    let date = "";
    const getNum = parseInt(email?.getEmailById?.dateCreated);
    if (getNum > 0) {
      date = BAGroupTimeFormat(getNum, true);
    }
    setDateFormat(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email?.getEmailById]);

  const rotateImg = (index, rotation, attachedBool) => {
    const next = index + 1;
    const rot = rotation + 90;

    let img = new Image();
    img.src = attachedBool
      ? attachImgs[index]?.originalData
      : imgData[index]?.originalData;

    img.onload = () => {
      let canvas = document.createElement("canvas");

      const wide = rot === 90 || rot === 270 ? img.height : img.width;
      const high = rot === 90 || rot === 270 ? img.width : img.height;

      canvas.width = wide;
      canvas.height = high;
      let ctx = canvas.getContext("2d");

      const drawH = rot === 90 || rot === 270 ? high : wide;
      const drawW = rot === 90 || rot === 270 ? wide : high;

      ctx.translate(wide / 2, high / 2);
      ctx.rotate(rot * (Math.PI / 180));
      ctx.drawImage(img, -drawH / 2, -drawW / 2);

      if (attachedBool) {
        setAttachImgData((prev) => [
          ...prev.slice(0, index),
          {
            ...prev[index],
            imageData: canvas.toDataURL("image/png", 1),
            rotation: rot,
          },
          ...prev.slice(next),
        ]);

        return;
      }

      if (!attachedBool) {
        setImgData((prev) => [
          ...prev.slice(0, index),
          {
            ...prev[index],
            imageData: canvas.toDataURL("image/png", 1),
            rotation: rot,
          },
          ...prev.slice(next),
        ]);
      }
    };
  };

  const handleSnackClose = () => {
    setOpenSnack({
      visible: false,
      message: "",
      duration: 2000,
    });
  };

  const handleSnack = (mes, ms) => {
    setOpenSnack({
      visible: true,
      message: mes,
      duration: ms || 2000,
    });
  };

  const filterImgIdsInHtml = () => {
    let html = email?.getEmailById?.html || "";
    let text = email?.getEmailById?.emailText || "";

    const regexBGWhite =
      /((background:|backgroundColor:|background-color:)\s?(white|#fff(?:fff)?|rgba?\(\s*255\s*,\s*255\s*,\s*255\s*(?:,\s*1\s*)?\));?)/gi;

    html = html.replace(
      regexBGWhite,
      "background: #525151; color: #fff !important;"
    );
    text = text.replace(
      regexBGWhite,
      "background: #525151; color: #fff !important;"
    );

    const htmlArr = html?.split(" ") || [];
    const textArr = text?.split(" ") || [];

    htmlArr.forEach((word) => {
      const idRegEx = /[a-f\d]{24}/i;
      if (idRegEx.test(word)) {
        setImgIds((prev) => {
          const newIds = prev.filter((id) => id !== word);
          return [...newIds, word];
        });
      }
      return;
    });

    textArr.forEach((word) => {
      const idRegEx = /[a-f\d]{24}/i;
      if (idRegEx.test(word)) {
        setImgIds((prev) => {
          const newIds = prev.filter((id) => id !== word);
          return [...newIds, word];
        });
      }
      return;
    });

    htmlRef.current.innerHTML = htmlArr.join(" ") || textArr.join(" ");
  };

  const getImgData = async () => {
    const { data } = await getIncImgIdData({
      variables: { pictureIdArr: imgIds },
    });

    if (data?.getImageById?.length > 0) {
      const addedOriginal = [...data?.getImageById];
      const updated = addedOriginal.map((arr) => {
        return { ...arr, originalData: arr?.imageData };
      });

      setImgData([...updated]);
    }
  };

  return (
    <>
      {(emailLoading || imageLoading) && (
        <AdminBDRSpinner message={"Loading..."} />
      )}
      <Snackbar
        open={openSnack.visible}
        autoHideDuration={openSnack.duration || 2000}
        onClose={handleSnackClose}
        message={openSnack.message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <div
        className="email-modal-container"
        onClick={() => {
          closeEmail();
        }}
      >
        <div
          className="email-modal-content"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <button className="email-modal-close" onClick={closeEmail}>
            Close
          </button>
          <ul className="email-modal-list">
            <li>
              <h5>
                <span className="green-color-text">From:</span>{" "}
                {email?.getEmailById?.from}
              </h5>
              <h5>
                <span className="green-color-text">Subject:</span>{" "}
                {email?.getEmailById?.emailSubject}
              </h5>
              <span>
                <span className="green-color-text">To:</span> {email?.getEmailById?.to}
              </span>
              <span>
                <span className="green-color-text">Date:</span> {dateFormat}
              </span>
              <button
                className="email-modal-button"
                onClick={() => {prepareReply(email?.getEmailById)}}
              >
                Reply
              </button>
            </li>
            <li ref={htmlRef}></li>
          </ul>
          <div className="email-modal-images-container">
            {imgData &&
              imgData?.map((item, index) => {
                const imgId = "imgEl" + item._id;
                const btnId = "btnEl" + item._id;

                const html = (
                  <div
                    key={item._id + index}
                    className="email-modal-images-box"
                  >
                    <div className="email-modal-img-hdr-box">
                      <h5 className="page-header">Image {index + 1}</h5>
                      <button
                        className="email-modal-copy-btn"
                        id={btnId}
                        onPointerUp={async () => {
                          const img = document.querySelector(`#${imgId}`);
                          const blob = new ClipboardItem({
                            "image/png": copyImageToClip(img.src),
                          });

                          navigator.clipboard.write([blob]);
                          handleSnack("Image copied", 1500);
                        }}
                      >
                        Copy <ContentCopyIcon />
                      </button>
                    </div>
                    <div className="email-modal-img-overlay-box">
                      <div className="email-modal-img-overlay">
                        <button
                          className="email-modal-action-img-button"
                          onClick={() => {
                            const rotation =
                              item?.rotation === 360 ? 0 : item?.rotation || 0;
                            rotateImg(index, rotation, false);
                          }}
                        >
                          <RotateRightIcon titleAccess="Rotate Image" />
                        </button>{" "}
                      </div>
                      <img
                        id={imgId}
                        className="email-modal-img"
                        src={item?.imageData}
                        alt={`door pic ${index}`}
                      />
                    </div>
                  </div>
                );

                return html;
              })}
            {(attachImgs?.length > 0 ||
              email?.getEmailById?.attachPdf?.length > 0) && (
              <>
                <h3 className="page-header email-modal-img-hdr">
                  Attachments:{" "}
                </h3>
                {attachImgs?.length > 0 &&
                  attachImgs?.map((item, index) => {
                    const imgId = "attImgEl" + item._id;
                    const btnId = "attBtnEl" + item._id;

                    const html = (
                      <div
                        key={item._id + index}
                        className="email-modal-images-box"
                      >
                        <div className="email-modal-img-hdr-box">
                          <h5 className="page-header">
                            Attached Image {index + 1}
                          </h5>
                          <button
                            className="email-modal-copy-btn"
                            id={btnId}
                            onPointerUp={async () => {
                              const img = document.querySelector(`#${imgId}`);
                              const blob = new ClipboardItem({
                                "image/png": copyImageToClip(img.src),
                              });

                              navigator.clipboard.write([blob]);
                              handleSnack("Image copied", 1500);
                            }}
                          >
                            Copy <ContentCopyIcon />
                          </button>
                        </div>

                        <div className="email-modal-img-overlay-box">
                          <div className="email-modal-img-overlay">
                            <button
                              className="email-modal-action-img-button"
                              onClick={() => {
                                const rotation =
                                  item?.rotation === 360
                                    ? 0
                                    : item?.rotation || 0;
                                rotateImg(index, rotation, true);
                              }}
                            >
                              <RotateRightIcon titleAccess="Rotate Image" />
                            </button>{" "}
                          </div>
                          <img
                            id={imgId}
                            className="email-modal-img"
                            src={item?.imageData}
                            alt={`door pic ${index}`}
                          />
                        </div>
                      </div>
                    );

                    return html;
                  })}
                {email?.getEmailById?.attachPdf?.length > 0 &&
                  email?.getEmailById?.attachPdf?.map((item, index) => {
                    const html = (
                      <div
                        key={item?.id + index}
                        className="email-modal-pdf-box"
                      >
                        {/iP(hone|od|ad)/.test(navigator.userAgent) ? (
                          <div>
                            <button
                              className="email-modal-copy-btn"
                              onClick={() => {
                                const destructData = item?.fileData.split(";");
                                // base64 string
                                const base64str = destructData[1].split(",")[1];
                                const pdfData = Buffer.from(
                                  base64str,
                                  "base64"
                                );

                                // create the blob object with content-type "application/pdf"
                                let blob = new Blob([pdfData], {
                                  type: "application/pdf",
                                });
                                let url = URL.createObjectURL(blob);

                                window.open(url);
                              }}
                            >
                              View PDF {index + 1}{" "}
                              {item?.fileName && `- ${item?.fileName}`}
                            </button>
                          </div>
                        ) : (
                          <>
                            <div className="email-modal-img-hdr-box">
                              <h5 className="page-header">
                                Attached Pdf {index + 1}
                              </h5>
                              <span style={{ fontSize: "smaller" }}>
                                File Name: {item?.fileName}
                              </span>
                            </div>
                            <div className="email-modal-pdf-file">
                              <iframe
                                src={item?.fileData + "#zoom=50"}
                                style={{ width: "300px", height: "370px" }}
                                title={`PDF ${index + 1}`}
                              ></iframe>
                            </div>
                          </>
                        )}
                      </div>
                    );

                    return html;
                  })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailModal;
